import { InvertColorsOff } from "@material-ui/icons";
import React, { useState } from "react";
import FacturaOtros from "./FacturaOtros";
import TipoPago from "./TipoPago";

const url = "https://parkingapi.ccg.hn:4000";

function CrearTicket({ ...props }) {
  const fecha = new Date();

  const [numeroC, setnumeroC] = useState(0);
  const [mostrarRTN, setMostrarRTN] = useState("none");
  const [mostrarCasco, setMostrarCasco] = useState("none");
  const [tipoT, settipoT] = useState("TicketN");

  const [mostrarF, setMostrarF] = useState("none");
  const [mostrarMas, setMostrarMas] = useState("none");
  const [mostrarInputFactura, setmostrarInputFactura] = useState("none");

  const [principal, setPrincipal] = useState("");

  const [total, setTotal] = useState(0);
  const [h, setH] = useState("");
  const [tipoV, setTipoV] = useState("");
  const [tipoC, setTipoC] = useState("Consumidor Final");
  const [nombre, setNombre] = useState("");
  const [rtn, setRTN] = useState("");
  const [recibido, setRecibido] = useState(0);
  const [cambio, setCambio] = useState(0);
  const [hora_Entrada, setHora_Entrada] = useState("");

  const [cai, setCai] = useState("");
  const [rango, setRango] = useState("");
  const [emision, setEmision] = useState("");
  const [nFactura, setNFactura] = useState("");
  const [rtn2, setRtn2] = useState("");

  const [nombreP, setNombreP] = useState("");

  const [marca, setMarca] = useState("");
  const [modelo, setModelo] = useState("");
  const [placa, setPlaca] = useState("");
  const [telefono, setTelefono] = useState("");
  const [Nfactura2, setNfactura2] = useState("");
  const [horaConsultada, sethoraConsultada] = useState("");
  const [mostrar1, setmostrar1] = useState("");
  const [mostrar2, setmostrar2] = useState("none");

  const [mostarBoton, setMostrarBoton] = useState(false);
  const v = [
    setMostrarF,
    setMostrarRTN,
    setTotal,
    setH,
    setTipoV,
    setTipoC,
    setNombre,
    setRTN,
    setRecibido,
    setCambio,
    setNFactura,
    setPrincipal,
    setTipoV,
  ];

  const [pago, setpago] = useState("Efectivo");
  const [banco, setbanco] = useState("");
  const [transferencia, settransferencia] = useState("");
  const [mTransferencia, setmTransferencia] = useState("none");
  const [check1, setcheck1] = useState(true);
  const [check2, setcheck2] = useState(false);
  const [check3, setcheck3] = useState(false);

  const hora = () => {
    let hora2 = "";
    let minutos = "";
    let segundos = "";

    if (fecha.getHours() < 10) {
      hora2 = "0" + fecha.getHours();
    } else {
      hora2 = fecha.getHours();
    }

    if (fecha.getMinutes() < 10) {
      minutos = "0" + fecha.getMinutes();
    } else {
      minutos = fecha.getMinutes();
    }

    if (fecha.getSeconds() < 10) {
      segundos = "0" + fecha.getSeconds();
    } else {
      segundos = fecha.getSeconds();
    }
    setH(hora2 + ":" + minutos);
  };

  const servicio = (valor) => {
    if (valor === "Parqueo x Hora Vehículo") {
      setMostrarCasco("none");
    } else if (valor === "Parqueo x Hora Motocicleta") {
      setMostrarCasco("");
    }
  };

  const cliente = (valor) => {
    if (valor === "Consumidor Final") {
      setMostrarRTN("none");
    } else if (valor === "Otro") {
      setMostrarRTN("");
    }
  };

  function calcularDiferencia(hora1, hora2) {
    // Convertir las horas a formato 24 horas
    var t1 = hora1.split(":");
    var t2 = hora2.split(":");

    // Crear objetos de fecha
    var fecha1 = new Date(1970, 0, 1, t1[0], t1[1]);
    var fecha2 = new Date(1970, 0, 1, t2[0], t2[1]);

    // Calcular la diferencia en milisegundos
    var diff = fecha2.getTime() - fecha1.getTime();

    // Convertir la diferencia a minutos y redondear al entero más cercano
    var diffMinutos = Math.round(diff / 60000);

    return diffMinutos;
  }

  const calculoTiempo = (response) => {
    const hora = response;

    const tiempoDeUsoEstacionamiento = calcularDiferencia(hora, h);

    if (tiempoDeUsoEstacionamiento <= 15) {
      setTotal(parseFloat(0).toFixed(2));
    } else {
      if (tipoV === "Parqueo x Hora Vehículo") {
     
          const costoPorMinuto = parseFloat(
            parseFloat(25).toFixed(2) / 60
          ).toFixed(10);
          setTotal(
            Math.ceil(costoPorMinuto * tiempoDeUsoEstacionamiento).toFixed(2)
          );
        
      } else if (tipoV === "Parqueo x Hora Motocicleta") {
      
          const costoPorMinuto = parseFloat(
            parseFloat(10).toFixed(2) / 60
          ).toFixed(10);
          setTotal(
            Math.ceil(costoPorMinuto * tiempoDeUsoEstacionamiento).toFixed(2)
          );
        
      }
    }
  };

  const enviarDatos = async () => {
    if (
      tipoV === "" ||
      hora_Entrada === "" ||
      total === 0 ||
      recibido === 0 ||
      tipoC == ""
    ) {
      alert("Todos los Campos son Obligatorios");
      return "faltan campos";
    } else if (parseFloat(recibido) < parseFloat(total)) {
      alert("Recibido menor que Total");
      return "menor";
    } else {
      setMostrarBoton(true);
      const fecha2 =
        fecha.getFullYear() +
        "/" +
        (fecha.getMonth() + parseInt(1)) +
        "/" +
        fecha.getDate();
      const id_Usuario = props.nombreI;

      let horaEntrada;

      if (tipoT === "TicketN") {
        horaEntrada = hora_Entrada;
      } else {
        horaEntrada = horaConsultada;
      }
      const res = await fetch(`${url}/crearFacturar`, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id_Usuario,
          fecha2,
          h,
          tipoV,
          tipoC,
          rtn,
          nombre,
          total,
          recibido,
          cambio,
          horaEntrada,
          numeroC,
          pago,
          banco,
          transferencia,
        }),
      });

      const data = await res.json();
      console.log(data);
      setCai(data[0].cai);
      setRango(data[0].RangoInicial);
      setEmision(data[0].fechaEmision);
      console.log(data[0].id_Factura);
      setNFactura(data[0].id_Factura);
      setRtn2(data[0].RTN);
    }
  };

  const consultarHoraFactura = async () => {
    const res = await fetch(`${url}/consultarHoraFactura`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Nfactura2,
      }),
    });

    const data = await res.json();

    if (data[0] === "No se hizo la consulta") {
      alert("Error en el sistema");
    } else if (data[0] === "No se encontró") {
      alert("No se encontró hora de salida en factura");
    } else {
      let hora2 = data[0].slice(0, 2);
      let minutos = data[0].slice(3, 5);
      const suma = parseInt(25) + parseInt(minutos);

      if (suma > 60) {
        hora2 = "0" + (parseInt(hora2) + parseInt(1));
        minutos = parseInt("0") + parseInt(suma - 60);
      } else if (suma === 60) {
        hora2 = parseInt(hora2) + parseInt(1);
        minutos = "00";
      } else {
        minutos = suma;
      }

      if (minutos < 10) {
        minutos = "0" + minutos;
      }

      setmostrar1("none");
      const selectV = document.getElementById("tipoVehiculo");
      console.log(data[1]);

      let d;

      let costoP = "";
      let impuestoP = "";

      if (data[1] === 1) {
        d = "Parqueo x Hora Vehículo";
        selectV.value = "Parqueo x Hora Vehículo";
        costoP = "21.74";
        impuestoP = "03.26";
      } else if (data[1] === 2) {
        selectV.value = "Parqueo x Hora Motocicleta";
        d = "Parqueo x Hora Motocicleta";
        costoP = "08.70";
        impuestoP = "01.30";
      }
      setTipoV(d);
      servicio(d);
      setTotal(0);
      hora();
      sethoraConsultada(hora2 + ":" + minutos);
      setHora_Entrada(hora2 + ":" + minutos);
      setmostrar2("");

      let hora3 = "";
      let minutos3 = "";
      let segundos = "";

      if (fecha.getHours() < 10) {
        hora3 = "0" + fecha.getHours();
      } else {
        hora3 = fecha.getHours();
      }

      if (fecha.getMinutes() < 10) {
        minutos3 = "0" + fecha.getMinutes();
      } else {
        minutos3 = fecha.getMinutes();
      }

      if (fecha.getSeconds() < 10) {
        segundos = "0" + fecha.getSeconds();
      } else {
        segundos = fecha.getSeconds();
      }
      let restaHora = parseInt(hora3) - parseInt(hora2);
      let restaminutos = parseInt(minutos3) - parseInt(minutos);

      if (restaHora === 0 && restaminutos <= 15) {
        setTotal(parseFloat(0).toFixed(2));
      } else if (restaHora > 1 && restaminutos < 0) {
        restaHora = parseInt(restaHora) - 1;
        restaminutos = parseInt(restaminutos) * parseInt(-1);
      } else if (restaHora === 1 && restaminutos < 0) {
        restaHora = 0;
        restaminutos = parseInt(60) - parseInt(restaminutos) * -1;
      } else if (restaHora > 1 && restaminutos < 0) {
        restaHora = parseInt(restaHora) - parseInt(1);
        restaminutos = parseInt(60) - parseInt(restaminutos) * -1;
      }
    }
  };

  return (
    <React.Fragment>
      <div
        className='contenedor-crearticket'
        style={{ display: `${principal}` }}
      >
        <div>
          <input
            type='radio'
            id='Nueva'
            name='TipoTicket'
            value='Factura Nueva'
            defaultChecked='true'
            onChange={(e) => {
              setmostrarInputFactura("none");
              setmostrar1("");
              setmostrar2("none");
              sethoraConsultada("");
              setNfactura2("");
            }}
          />
          <label for='Nueva'>Ingresar Hora</label>
          <input
            type='radio'
            id='Factura'
            name='TipoTicket'
            value='Dato Factura'
            onChange={(e) => {
              setmostrarInputFactura("");
            }}
          />
          <label for='Factura'>Hora de Factura creada</label>
        </div>
        <div
          className='contenedor-crearticket-grupo'
          style={{ display: `${mostrarInputFactura}` }}
        >
          <div className='contenedor-crearticket-grupo1'>
            <div className='contenedor-crearticket-grupo2'>
              <label>Número de Factura</label>
              <input
                type='number'
                className='form-control'
                style={{ fontSize: "1.8rem" }}
                value={Nfactura2}
                onChange={(e) => {
                  setNfactura2(e.target.value);
                }}
              />
            </div>
            <div className='contenedor-crearticket-grupo2'>
              <button
                style={{ fontSize: "2rem", margin: "auto 0" }}
                onClick={() => {
                  if (Nfactura2 === "No ha ingresado número de factura") {
                  } else {
                    consultarHoraFactura();
                  }
                }}
              >
                Consultar Factura
              </button>
            </div>
          </div>
        </div>
        <div className='contenedor-crearticket-grupo'>
          <div className='contenedor-crearticket-grupo2'>
            <label>Tipo Ticket</label>
            <select
              style={{ fontSize: "1.8rem" }}
              className='input-crearticket'
              value={tipoV}
              id='tipoVehiculo'
              onChange={(e) => {
                setTipoV(e.target.value);
                servicio(e.target.value);
                setTotal(0);
                hora();
              }}
            >
              <option value=''>Seleccione una Opción</option>
              <option value='Parqueo x Hora Vehículo'>
                Parqueo x Hora Vehículo
              </option>
              <option value='Parqueo x Hora Motocicleta'>
                Parqueo x Hora Motocicleta
              </option>
            </select>
          </div>
        </div>

        <div className='' style={{ display: `${mostrarCasco}` }}>
          <div className='contenedor-crearticket-grupo '>
            <div className='contenedor-crearticket-grupo2'>
              <label>Numero de Casco</label>
              <input
                type='Number'
                className='form-control'
                style={{ fontSize: "1.8rem" }}
                value={numeroC}
                onChange={(e) => {
                  setnumeroC(e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        <div className='contenedor-crearticket-grupo'>
          <div className='contenedor-crearticket-grupo1'>
            <div
              className='contenedor-crearticket-grupo2'
              style={{ display: `${mostrar1}` }}
            >
              <label>Hora Entrada</label>
              <input
                type='time'
                className='input-crearticket2'
                value={hora_Entrada}
                style={{ fontSize: "1.8rem" }}
                onChange={(e) => {
                  setHora_Entrada(e.target.value);
                  calculoTiempo(e.target.value);
                  return;
                }}
              />
            </div>
            <div
              className='contenedor-crearticket-grupo2'
              style={{ display: `${mostrar2}` }}
            >
              <label>Hora Entrada</label>
              <input
                type='text'
                className='input-crearticket2'
                style={{ fontSize: "1.8rem" }}
                value={horaConsultada}
                disabled='disabled'
              />
            </div>

            <div className='contenedor-crearticket-grupo2'>
              <label> Hora Salida</label>
              <input
                type='text'
                disabled='disabled'
                value={h}
                className='input-crearticket2'
                style={{ fontSize: "1.8rem" }}
              />
            </div>
          </div>
        </div>

        <div className='row m-3'>
          <div className='offset-md-3 col-md-6'>
            <div className='input-group'></div>
          </div>
        </div>

        <div className='contenedor-crearticket-grupo'>
          <div className='contenedor-crearticket-grupo2'>
            <label>Total</label>
            <input
              style={{ fontSize: "1.8rem" }}
              className='input-crearticket'
              disabled='disabled'
              value={total}
            />
          </div>
        </div>

        <div className='contenedor-crearticket-grupo'>
          <div className='contenedor-crearticket-grupo1'>
            <div className='contenedor-crearticket-grupo2'>
              <label>Recibido</label>
              <input
                type='text'
                className='input-crearticket2'
                style={{ fontSize: "1.8rem" }}
                value={recibido}
                onChange={(e) => {
                  setRecibido(e.target.value);
                }}
              />
            </div>
            <div className='contenedor-crearticket-grupo2'>
              <label>Cambio</label>
              <input
                disabled='disabled'
                type='text'
                className='input-crearticket2'
                style={{ fontSize: "1.8rem" }}
                value={parseFloat(recibido - total).toFixed(2)}
                onChange={(e) => {
                  setCambio(e.target.value);
                }}
              />
            </div>
          </div>

          <div className='contenedor-crearticket-grupo'>
            <div className='contenedor-crearticket-grupo1'>
              <TipoPago
                setpago={setpago}
                setbanco={setbanco}
                banco={banco}
                check1={check1}
                check2={check2}
                check3={check3}
                setcheck1={setcheck1}
                setcheck2={setcheck2}
                setcheck3={setcheck3}
                settransferencia={settransferencia}
                transferencia={transferencia}
                setmTransferencia={setmTransferencia}
                mTransferencia={mTransferencia}
              />
            </div>
          </div>

          <div className=' col-md-3'>
            <div className='input-group'></div>
          </div>
        </div>

        <div className='contenedor-crearticket-grupo'>
          <div className='contenedor-crearticket-grupo2'>
            <label>Tipo Cliente</label>
            <select
              style={{ fontSize: "1.8rem" }}
              className='input-crearticket'
              value={tipoC}
              onChange={(e) => {
                setTipoC(e.target.value);
                cliente(e.target.value);
              }}
            >
              <option value=''>Seleccione una Opción</option>
              <option value='Consumidor Final'>Consumidor Final</option>
              <option value='Otro'>Otro</option>
            </select>
          </div>
        </div>

        <div
          className='contenedor-crearticket-grupo'
          style={{
            display: `${mostrarRTN}`,
            backgroundColor: "white",
            marginTop: "1rem",
          }}
        >
          <div className='contenedor-crearticket-grupo1'>
            <div className='contenedor-crearticket-grupo2'>
              <label>RTN</label>
              <input
                type='text'
                className='input-crearticket2'
                style={{ fontSize: "1.8rem" }}
                value={rtn}
                onChange={(e) => {
                  setRTN(e.target.value);
                }}
              />
            </div>
            <div className='contenedor-crearticket-grupo2'>
              <label>Nombre</label>
              <input
                type='text'
                className='input-crearticket2'
                style={{ fontSize: "1.8rem" }}
                value={nombre}
                onChange={(e) => {
                  setNombre(e.target.value);
                }}
              />
            </div>
          </div>

          <div className=' col-md-3'>
            <div className='input-group'></div>
          </div>
        </div>

        <div className='row'>
          <div className='offset-md-4 col-md-6'>
            <button
              className='btn btn-verde form-control m-3'
              type='button'
              disabled={mostarBoton}
              style={{ fontSize: "1.8rem" }}
              onClick={() => {
                if (
                  tipoV === "" ||
                  tipoC === "" ||
                  total === 0 ||
                  recibido === 0
                ) {
                  alert("Todos los Campos son Obligatorios");
                } else if (parseFloat(recibido) < parseFloat(total)) {
                  alert("Recibido es Menor a Total");
                } else {
                  enviarDatos().then((response) => {
                    setMostrarF("");
                    setPrincipal("none");
                    setMostrarBoton(false);
                    props.setColor("");
                    props.setMostrarLista("none");
                    setTimeout(() => {
                      window.print();
                    }, 500);
                    return;
                  });
                }
              }}
            >
              Crear Factura
            </button>
          </div>
        </div>
      </div>

      <div style={{ display: `${mostrarF}` }}>
        <FacturaOtros
          cai={cai}
          rango={rango}
          Emision={emision}
          nFactura={nFactura}
          rtn2={rtn2}
          tipoC={tipoC}
          rtn={rtn}
          nombre={nombre}
          total={total}
          recibido={recibido}
          tipoV={tipoV}
          hora_Entrada={hora_Entrada}
          h={h}
          v={v}
          pago={pago}
          banco={banco}
          transferencia={transferencia}
          mTransferencia={mTransferencia}
          setMostrarLista={props.setMostrarLista}
          setColor={props.setColor}
          mostrarMas={mostrarMas}
          nombreP={nombreP}
          marca={marca}
          modelo={modelo}
          placa={placa}
          telefono={telefono}
          setNombreP={setNombreP}
          setMarca={setMarca}
          setModelo={setModelo}
          setPlaca={setPlaca}
          setTelefono={setTelefono}
          setpago={setpago}
          setbanco={setbanco}
          settransferencia={settransferencia}
          setmTransferencia={setmTransferencia}
          setcheck1={setcheck1}
          setcheck2={setcheck2}
          setcheck3={setcheck3}
        ></FacturaOtros>
      </div>
    </React.Fragment>
  );
}

export default CrearTicket;
